import React, { useState } from "react"
import { navigate } from "gatsby-link"

import { Container, Sidebar } from "../../components"
import Seo from "../../components/seo"
import { ContentWrapper } from "../../elements"
import { HeaderTitle } from "../../section"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Container>
      <Seo />
      <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
        <HeaderTitle title="Contact" subTitle="お問い合わせ" />
        <ContentWrapper>
          <div className="col-span-full lg:col-span-2">
            <div className="mb-12">
              <p className="mb-4 text-sm leading-6 md:text-base md:leading-7">
                お仕事のご依頼等は下記フォームご記入のうえ、ご送信ください。
                <br />
                通常2〜3日程でご返信させていただきます。1週間以上経過しても返信がない場合は、お手数ですがもう一度お問い合わせください。
              </p>
            </div>
            <div className="mb-12">
              <form
                name="contact"
                method="post"
                action="/contact/success"
                data-netlify="true"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                <div className="relative mb-4">
                  <input
                    className="
                    px-4
                    py-2
                    w-full
                    text-sm
                    border border-l-0 border-r-0 border-t-0 border-black
                    rounded
                    md:px-6 md:py-3 md:text-sm
                  "
                    type="text"
                    name="search"
                    placeholder="お名前"
                    onChange={handleChange}
                    required
                  />
                  <span className="text-primary-red absolute left-0 top-0 text-sm">
                    *
                  </span>
                </div>
                <div className="relative mb-4">
                  <input
                    className="
                    px-4
                    py-2
                    w-full
                    text-sm
                    border border-l-0 border-r-0 border-t-0 border-black
                    rounded
                    md:px-6 md:py-3 md:text-sm
                  "
                    type="text"
                    name="email"
                    placeholder="メールアドレス"
                    onChange={handleChange}
                    required
                  />
                  <span className="text-primary-red absolute left-0 top-0 text-sm">
                    *
                  </span>
                </div>
                <div className="relative mb-8">
                  <input
                    className="
                    px-4
                    py-2
                    w-full
                    text-sm
                    border border-l-0 border-r-0 border-t-0 border-black
                    rounded
                    md:px-6 md:py-3 md:text-sm
                  "
                    type="tel"
                    name="tel"
                    placeholder="電話番号"
                    onChange={handleChange}
                  />
                </div>
                <div className="relative mb-8">
                  <textarea
                    className="
                    px-4
                    py-2
                    w-full
                    text-sm
                    border border-black
                    rounded
                    md:px-6 md:py-3 md:text-sm
                  "
                    type="text"
                    name="content"
                    placeholder="お問い合わせ内容"
                    rows="8"
                    onChange={handleChange}
                    required
                  ></textarea>
                  <span className="text-primary-red absolute -top-4 left-0 text-sm">
                    *
                  </span>
                </div>
                <div className="flex col-span-full items-center justify-center w-full">
                  <button
                    className="w-64 h-12 text-white bg-black"
                    type="submit"
                  >
                    送信する
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Sidebar />
        </ContentWrapper>
      </section>
    </Container>
  )
}

export default Contact
